/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import Select from 'react-select'

const Voucher = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer") {
        window.location.assign('/')
    }
    const [voucher, setVoucher] = useState([]);
    const getVoucher = async () => {
        const { data } = await axios.get("/finance/voucher-list");
        setVoucher(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 3, 4]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: []
                                },

                            ],
                            footerCallback: function () {
                                var api = this.api();
                                var intVal = function (i) {
                                    var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                                    var parsed = Number(cleanString);
                                    if (isNaN(parsed)) {
                                        console.log("Could not parse: ", i);
                                        return 0;
                                    }
                                    return parsed;
                                };
                                var currencySums = {
                                    "$": {
                                        amount: 0,
                                        totalCost: 0,
                                    },
                                    "د.ع": {
                                        amount: 0,
                                        totalCost: 0,
                                    }
                                };

                                var columns = [3, 4];
                                var fields = ['amount', 'totalCost'];
                                columns.forEach((colIndex, fieldIndex) => {
                                    api.column(colIndex).data().each(function (value, index) {
                                        var currency = value.includes("د.ع") ? "د.ع" : "$";
                                        currencySums[currency][fields[fieldIndex]] += intVal(value);
                                    });
                                });

                                fields.forEach((field, index) => {
                                    var html = Object.entries(currencySums).map(([currency, sums]) => {
                                        return `${commaNumber(sums[field])} ${currency}`;
                                    }).join("<br>");
                                    $(api.column(columns[index]).footer()).html(html);
                                });
                            },

                        })
                    }
                    var api = window.$('.dt-tbl').DataTable();
                    api.on('draw.dt', function () {
                        api.columns().footer().each(function (footer) { $(footer).html('') });
                        var intVal = function (i) {
                            var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                            var parsed = Number(cleanString);
                            if (isNaN(parsed)) {
                                console.log("Could not parse: ", i);
                                return 0;
                            }
                            return parsed;
                        };
                        var currencySums = {
                            "$": {
                                amount: 0,
                                totalCost: 0,
                            },
                            "د.ع": {
                                amount: 0,
                                totalCost: 0,
                            }
                        };

                        var columns = [3, 4];
                        var fields = ['amount', 'totalCost'];
                        columns.forEach((colIndex, fieldIndex) => {
                            api.column(colIndex).nodes().to$().filter(':visible').each(function (index, element) {
                                var value = $(element).text();
                                var currency = value.includes("د.ع") ? "د.ع" : "$";
                                currencySums[currency][fields[fieldIndex]] += intVal(value);
                            });
                        });

                        fields.forEach((field, index) => {
                            var html = Object.entries(currencySums).map(([currency, sums]) => {
                                return `${commaNumber(sums[field])} ${currency}`;
                            }).join("<br>");
                            $(api.column(columns[index]).footer()).html(html);
                        });
                    });
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };
    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: item.symbol
            };
        }));
    }
    const [safe, setsafes] = useState([]);
    const getSafes = async () => {
        const { data } = await axios.get("/finance/safe");
        setsafes(data.map((item) => {
            return {
                value: item.id,
                label: `${item.safe_code} - ${item[`safe_name`]}`
            };
        }));
    };
    const [voucherContent, setVoucherContent] = useState([]);
    const getVoucherContent = async (id) => {
        const { data } = await axios.get(`/finance/voucher/content/${id}`);
        setVoucherContent(data);
    };
    const [headerForm, setHeaderForm] = useState([]);
    const [contentForm, setContentForm] = useState([]);
    const getVoucherForm = async (id) => {
        const { data } = await axios.get(`/finance/voucher/header/${id}`);
        setHeaderForm(
            <form className="form-sample" onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const data = Object.fromEntries(formData);
                axios.put(`/finance/voucher/header/${id}`, data).then(res => {
                    if (!isNaN(res.data)) {
                        window.$(`#editvoucher${id}`).modal('hide');
                        window.$(`#confirmation-modal${id}`).modal('show');
                        getVoucherContent(id);
                    }
                })
            }}>
                <div className="modal-body">
                    <div className="mb-3">
                        <div className="form-group row">
                            <label htmlFor="title_krd" className="col-12 form-label">{translate(`title_${lang}`)} ({translate("kurdi")}) <span className="text-danger">*</span></label>
                            <div className="col-12">
                                <input type="text" className="form-control" name="title" defaultValue={data[0].title} required />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="form-group row">
                            <label htmlFor="date" className="col-12 form-label">{translate(`date_${lang}`)}</label>
                            <div className="col-12">
                                <input type="date" className="form-control" id="created" name="created" defaultValue={new Date().toISOString(data[0].created).split('T')[0]} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                    <button type="submit" className="btn btn-sm btn-primary">
                        <span className="ms-2">{translate(`next_${lang}`)}</span>
                        <icon.ArrowLeft />
                    </button>
                </div>
            </form>
        );
        setContentForm(
            <>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <label htmlFor="safe_id" className="form-label">{translate(`safes_${lang}`)} <span className="text-danger">*</span></label>
                        <Select options={safe} name="safe_id" placeholder={translate(`safes_${lang}`)} required />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <label htmlFor="currency_id_" className="form-label">{translate(`currency_${lang}`)} <span className="text-danger">*</span></label>
                        <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} required />
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group row">
                        <label htmlFor="title_krd" className="col-12 form-label"> {translate(`title_${lang}`)} ({translate("kurdi")}) <span className="text-danger">*</span></label>
                        <div className="col-12">
                            <input type="text" className="form-control" name="title" placeholder={translate(`title_${lang}`)} required />
                        </div>
                    </div>
                </div> 
                <div className="mb-3">
                    <div className="form-group row">
                        <label htmlFor="credit" className="col-12 form-label">{translate(`credit_${lang}`)}</label>
                        <div className="col-12">
                            <input type="number" className="form-control" name="credit" step={"any"} defaultValue={0} required />
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group row">
                        <label htmlFor="debit" className="col-12 form-label">{translate(`debt_${lang}`)}</label>
                        <div className="col-12">
                            <input type="number" className="form-control" name="debit" step={"any"} defaultValue={0} required />
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <button type="sumbit" className="btn btn-primary">{translate(`add_${lang}`)}</button>
                </div>
            </>
        );
    }
    useEffect(() => {
        getVoucher();
        getCurrency();
        getSafes();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`vouchers_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`vouchers_${lang}`)}</h6>
                                </div>
                                {(role === "admin" || role === "superuser") && (
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#newvoucher">
                                            <icon.Plus size="20" />
                                            <span className="ms-2">{translate(`new_voucher_${lang}`)}</span>
                                        </button>
                                    </div>
                                )}
                                <div className="modal fade" id="newvoucher" tabIndex={-2} aria-labelledby="newvoucher" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">{translate(`new_voucher_${lang}`)} </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>
                                            <form className="form-sample" onSubmit={(e) => {
                                                e.preventDefault();
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData);
                                                axios.post("/finance/voucher/header", data).then(res => {
                                                    if (!isNaN(res.data)) {
                                                        window.$('#newvoucher').modal('hide');
                                                        window.$('#confirmation-modal').modal('show');
                                                        $('#voucher_header_id').val(res.data[0]);
                                                        getVoucherContent(res.data[0]);
                                                    }
                                                })
                                            }}>
                                                <div className="modal-body">
                                                    <div className="mb-3">
                                                        <div className="form-group row">
                                                            <label htmlFor="title_krd" className="col-12 form-label">{translate(`title_${lang}`)} ({translate("kurdi")}) <span className="text-danger">*</span></label>
                                                            <div className="col-12">
                                                                <input type="text" className="form-control" name="title" placeholder={translate(`title_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="mb-3">
                                                        <div className="form-group row">
                                                            <label htmlFor="date" className="col-12 form-label">{translate(`date_${lang}`)}</label>
                                                            <div className="col-12">
                                                                <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString().split('T')[0]} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                    <button type="submit" className="btn btn-sm btn-primary">
                                                        <span className="ms-2">{translate(`next_${lang}`)}</span>
                                                        <icon.ArrowLeft />
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="confirmation-modal" tabIndex={-1} aria-labelledby="confirmation-modal" aria-hidden="true">
                                    <div className="modal-dialog modal-xl">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="confirmation-modal-label">{translate(`detail_${lang}`)}</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    data.voucher_header_id = $('#voucher_header_id').val()
                                                    axios.post("/finance/voucher/content", data).then(res => {
                                                        if (!isNaN(res.data)) {
                                                            getVoucherContent($('#voucher_header_id').val());
                                                            getVoucher();
                                                        }
                                                    })
                                                }}>
                                                    <input type="hidden" name="voucher_header_id" id="voucher_header_id" />
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="safe_id" className="form-label">{translate(`safes_${lang}`)}</label>
                                                            <Select options={safe} name="safe_id" placeholder={translate(`safes_${lang}`)} required />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="currency_id" className="form-label">{translate(`currency_${lang}`)}</label>
                                                            <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} required />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="form-group row">
                                                            <label htmlFor="title_krd" className="col-12 form-label">{translate(`title_${lang}`)} ({translate("kurdi")}) <span className="text-danger">*</span></label>
                                                            <div className="col-12">
                                                                <input type="text" className="form-control" name="title" placeholder={translate(`title_${lang}`)} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="form-group row">
                                                            <label htmlFor="credit" className="col-12 form-label">{translate(`credit_${lang}`)}</label>
                                                            <div className="col-12">
                                                                <input type="number" className="form-control" step={"any"} name="credit" defaultValue={0} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="form-group row">
                                                            <label htmlFor="debit" className="col-12 form-label">{translate(`debt_${lang}`)}</label>
                                                            <div className="col-12">
                                                                <input type="number" className="form-control" step={"any"} name="debit" defaultValue={0} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <button type="sumbit" className="btn btn-primary">{translate(`add_${lang}`)}</button>
                                                    </div>
                                                </form>
                                                <div className="table-responsive">
                                                    <table className="table table-sm table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{translate(`safes_${lang}`)}</th>
                                                                <th>{translate(`title_${lang}`)}</th>
                                                                <th>{translate(`credit_${lang}`)}</th>
                                                                <th>{translate(`debt_${lang}`)}</th>
                                                                <th>{translate(`date_${lang}`)}</th>
                                                                <th>{translate(`options_${lang}`)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {voucherContent && voucherContent.map((item) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.id}</td>
                                                                    <td>{item[`safe_name`]}</td>
                                                                    <td>{item.title}</td>
                                                                    <td>{item.credit} {item.symbol}</td>
                                                                    <td>{item.debit} {item.symbol}</td>
                                                                    <td>{new Date(item.created).toLocaleDateString()}</td>
                                                                    <td>
                                                                        {(role === "admin" || role === "superuser") && (
                                                                            <button type="button" className="btn btn-danger" onClick={function () {
                                                                                axios.delete(`/finance/voucher/content/${item.id}`).then(() => {
                                                                                    getVoucher();
                                                                                    getVoucherContent(item.voucher_header_id);
                                                                                })
                                                                            }}><icon.Trash size="16" /></button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th> 
                                        <th>{translate(`title_${lang}`)}</th>
                                        <th>{translate(`credit_${lang}`)}</th>
                                        <th>{translate(`debt_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                        <th>{translate(`accounts_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {voucher && voucher.map((item, index) => (
                                        item.id ? (
                                            <tr key={index}>
                                                <td>{item.id}</td> 
                                                <td>{item.title}</td>
                                                <td>{commaNumber(item.credit)} </td>
                                                <td>{commaNumber(item.debit)} </td>
                                                <td>{item.username}</td>
                                                <td>{new Date(item.created).toLocaleDateString()}</td>
                                                <td>
                                                    {(role === "admin" || role === "superuser") && (
                                                        <>
                                                            <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#editvoucher${item.id}`} onClick={() => { getVoucherForm(item.id) }}>
                                                                <icon.Edit size="16" />
                                                            </span>

                                                            <span type="button" className="badge bg-danger" onClick={() => {
                                                                Swal.fire({
                                                                    title: translate(`warning_${lang}`),
                                                                    text: translate(`warning_text_${lang}`),
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: translate(`yes_${lang}`),
                                                                    cancelButtonText: translate(`no_${lang}`)
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        axios.delete(`/finance/voucher/${item.id}`).then(() => {
                                                                            getVoucher();
                                                                        })
                                                                    }
                                                                })
                                                            }}>
                                                                <icon.Trash size="16" />
                                                            </span>
                                                        </>
                                                    )}
                                                    <div className="modal fade" id={`editvoucher${item.id}`} tabIndex={-1} aria-labelledby={`editvoucher${item.id}`} aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"> {translate(`edit_${lang}`)} </h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                                </div>
                                                                {headerForm}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal fade" id={`confirmation-modal${item.id}`} tabIndex={-1} aria-labelledby="confirmation-modal-label" aria-hidden="true">
                                                        <div className="modal-dialog modal-xl">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id={`confirmation-modal${item.id}`}>{translate(`detail_${lang}`)}</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <form className="form-sample" onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        const formData = new FormData(e.target);
                                                                        const data = Object.fromEntries(formData);
                                                                        data.voucher_header_id = item.id;
                                                                        axios.post('/finance/voucher/content', data).then(res => {
                                                                            if (!isNaN(res.data)) {
                                                                                e.target.reset();
                                                                                getVoucherContent(item.id);
                                                                                getVoucher();
                                                                            }
                                                                        })
                                                                    }}>
                                                                        {contentForm}
                                                                    </form>
                                                                    <div className="table-responsive">
                                                                        <table className="table table-sm table-striped">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>{translate(`safes_${lang}`)}</th>
                                                                                    <th>{translate(`title_${lang}`)}</th>
                                                                                    <th>{translate(`credit_${lang}`)}</th>
                                                                                    <th>{translate(`debt_${lang}`)}</th>
                                                                                    <th>{translate(`date_${lang}`)}</th>
                                                                                    <th>{translate(`options_${lang}`)}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {voucherContent.map((obj) => (
                                                                                    <tr key={obj.id}>
                                                                                        <td>{obj.id}</td>
                                                                                        <td>{obj.safe_name}</td>
                                                                                        <td>{obj.title}</td>
                                                                                        <td>{obj.credit} {obj.symbol}</td>
                                                                                        <td>{obj.debit} {obj.symbol}</td>
                                                                                        <td>{new Date(obj.created).toLocaleDateString()}</td>
                                                                                        <td>
                                                                                            <button type="button" className="btn btn-danger" onClick={function () {
                                                                                                axios.delete(`/finance/voucher/content/${obj.id}`).then(() => {
                                                                                                    getVoucher();
                                                                                                    getVoucherContent(item.id);
                                                                                                })
                                                                                            }}>
                                                                                                <icon.Trash size="16" />
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td >
                                            </tr >
                                        ) : null
                                    ))}
                                </tbody >
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </table >
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Voucher;