import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import commaNumber from 'comma-number';
import axios from "axios";
import $ from "jquery";

const Sinvoice = ({ translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "sales") {
        window.location.assign('/')
    }
    const payment_id = useParams().id

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/sale/payment/${payment_id}`);
        setData(data);
    }

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        // eslint-disable-next-line
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`invoice_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body print">
                            <div className="container-fluid d-flex justify-content-between">
                                <div className="col-lg-3 ps-0">
                                    <a href="/" className="noble-ui-logo d-block mt-3"> Lion <span> Land </span></a>
                                    <p className="mt-1 mb-1">{translate(`company_name_${lang}`)}<br /> <b>{translate(`company_text_${lang}`)}</b></p>
                                    <small className="text-muted">{translate(`address_${lang}`)}: {translate(`company_address_${lang}`)}</small>
                                    <h5 className="mt-5 mb-2 text-muted">{translate(`company_${lang}`)}</h5>
                                    <p>{data[`full_name`]}</p>
                                </div>
                                <div className="col-lg-3 pe-0">
                                    <h4 className="fw-bolder text-uppercase text-end mt-4 mb-2">{translate(`invoice_${lang}`)}</h4>
                                    <h6 className="text-end mb-5 pb-4">{translate(`invoice_no_${lang}`)}-{data.id}</h6>
                                    <p className="text-end mb-1">{translate(`total_${lang}`)}</p>
                                    <h4 className="text-end fw-normal">{commaNumber(data.amount)} {data.symbol}</h4>
                                    <h6 className="mb-0 mt-3 text-end fw-normal mb-2"><span className="text-muted">{translate(`date_${lang}`)} :</span> {new Date(data.created).toLocaleDateString()}</h6>
                                </div>
                            </div>
                            <div className="container-fluid mt-5 w-100">
                                <div className="row">
                                    <div className="col-xl-6 ms-auto px-5 mt-4">
                                        <div className="table-responsive">
                                            <table className="table table-sm">
                                                <tbody>
                                                    <tr >
                                                        <td className="text-bold-800">{translate(`title_${lang}`)}</td>
                                                        <td className="text-bold-800 text-end">{data.title} </td>
                                                    </tr>
                                                    <tr className="bg-light">
                                                        <td className="text-bold-800"> {translate(`amount_${lang}`)}</td>
                                                        <td className="text-bold-800 text-end">{commaNumber(data.amount)}  {data.symbol}</td>
                                                    </tr>
                                                    <tr className="bg-light">
                                                        <td className="text-bold-800">{translate(`note_${lang}`)}</td>
                                                        <td className="text-bold-800 text-end">{data.description} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Sinvoice;