import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import $ from "jquery";

const Sidebar = ({ icon, translate, lang, role }) => {
    const navigate = useNavigate();
    const [nav, setNav] = useState([]);

    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (!res.data) {
                navigate('/login')
            }
        });
        axios.get('/info').then((res) => {
            localStorage.setItem("role", res.data.role)
            if (res.data.role === "admin" || res.data.role === "superuser" || res.data.role === "observer") {
                setNav(
                    <ul className="nav">
                        <li className="nav-item nav-category">{translate(`main_${lang}`)}</li>
                        <li className="nav-item">
                            <Link to="/" className="nav-link">
                                <icon.BarChart2 className="link-icon" />
                                <span className="link-title"> {translate(`stats_${lang}`)} </span>
                            </Link>
                        </li>
                        <li className="nav-item auth">
                            <Link to="/orders/list" className="nav-link">
                                <icon.Download className="link-icon" />
                                <span className="link-title"> {translate(`orders_${lang}`)}</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/sales/list" className="nav-link">
                                <icon.Truck className="link-icon" />
                                <span className="link-title"> {translate(`sales_${lang}`)}</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/waste/list" className="nav-link">
                                <icon.Truck className="link-icon" />
                                <span className="link-title"> {translate(`damages_${lang}`)}</span>
                            </Link>
                        </li> 
                        <li className="nav-item nav-category">{translate(`logs_${lang}`)}</li>
                       
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="collapse" href="#report" role="button" aria-expanded="false" aria-controls="report">
                                <icon.BookOpen className="link-icon" />
                                <span className="link-title">{translate(`reports_${lang}`)}</span>
                                <icon.ChevronDown className="link-arrow" />
                            </a>
                            <div className="collapse" id="report">
                                <ul className="nav sub-menu">
                                    <li className="nav-item">
                                        <Link to="/report/item-balance" className="nav-link">
                                            {translate(`available_items_${lang}`)}
                                        </Link>
                                    </li> 
                                    <li className="nav-item">
                                        <Link to="/report/vouchers" className="nav-link">
                                            {translate(`voucher_trx_${lang}`)}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/finance/expenses/report" className="nav-link">
                                            {translate(`report_expenses_${lang}`)}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/finance/revenue" className="nav-link">
                                         Revenue Reports 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/finance/sharedrevenue" className="nav-link">
                                        shared revenue 
                                        </Link>
                                    </li>
                                     

                                </ul>
                            </div>
                        </li> 
                        <li className="nav-item nav-category">{translate(`accounting_${lang}`)} </li>
                        <li className="nav-item">
                            <Link to="/finances/safe" className="nav-link">
                                <icon.Key className="link-icon" />
                                <span className="link-title"> {translate(`safes_${lang}`)}</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/finance/voucher" className="nav-link">
                                <icon.Book className="link-icon" />
                                <span className="link-title"> {translate(`vouchers_${lang}`)}</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/finance/expenses" className="nav-link">
                                <icon.File className="link-icon" />
                                <span className="link-title"> {translate(`expenses_${lang}`)}</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="collapse" href="#finance" role="button" aria-expanded="false" aria-controls="finance">
                                <icon.CreditCard className="link-icon" />
                                <span className="link-title">{translate(`payments_${lang}`)}</span>
                                <icon.ChevronDown className="link-arrow" />
                            </a>
                            <div className="collapse" id="finance">
                                <ul className="nav sub-menu"> 
                                    <li className="nav-item">
                                        <Link to="/sales/payment" className="nav-link">{translate(`sale_payment_${lang}`)}</Link>
                                    </li> 
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link to="/report/balances" className="nav-link">
                                <icon.Activity className="link-icon" />
                                <span className="link-title"> {translate(`account_balance_${lang}`)}</span>
                            </Link>
                        </li> 
                    </ul>
                )
            }
            
        });
        // eslint-disable-next-line
    }, [navigate]);

    return (
        <nav className="sidebar">
            <div className="sidebar-header">
                <Link to="/" className="sidebar-brand">
                    Lion <span> Land </span>
                </Link>
                <div className="sidebar-toggler not-active" onClick={() => {
                    $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                    if (window.matchMedia('(min-width: 992px)').matches) {
                        $('body').toggleClass('sidebar-folded');
                    } else if (window.matchMedia('(max-width: 991px)').matches) {
                        $('body').toggleClass('sidebar-open');
                    }
                    $(".sidebar .sidebar-body").on('hover', () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').addClass("open-sidebar-folded");
                        }
                    }, () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').removeClass("open-sidebar-folded");
                        }
                    });
                }}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div className="sidebar-body">
                {nav}
            </div>
        </nav>
    );
}

export default Sidebar;