/*eslint-disable*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Select from 'react-select'
import commaNumber from "comma-number";


var totalUSD = 0;
var paymentUSD = 0;
var balanceUSD = 0;

var totalIQD = 0;
var paymentIQD = 0;
var balanceIQD = 0;

const PaymentDetailsCustomers = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer") {
        window.location.assign('/')
    }
    const [data, setData] = useState([]);

    const getData = async () => {
        const { data } = await axios.get("/customer/customerpaymentDetailCustomersAPI");
        setData(data);
        console.log(dataGroupBy);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 3, 7]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [0, 4, 5, 6, 8]
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    const [dataGroupBy, setDataGroupBy] = useState([]);
    const getDataGroupBy = async () => {
        const dataGroupBy = await axios.get("/customer/customerpaymentDetailCustomerAPIGroupBy");
        totalUSD = await dataGroupBy.data[0].amount;
        paymentUSD = await dataGroupBy.data[0].amount_paid;
        balanceUSD = await dataGroupBy.data[0].amount_due;

        // totalIQD = await dataGroupBy.data[1].amount;
        // paymentIQD = await dataGroupBy.data[1].amount_paid;
        // balanceIQD = await dataGroupBy.data[1].amount_due;

    };

    const [customerGroup, setCustomerGroup] = useState([]);
    const [selectGroup, setSelectGroup] = useState([]);
    const getCustomerGroup = async () => {
        const { data } = await axios.get("/customer/group");
        setCustomerGroup(data);
        setSelectGroup(data.map((item) => {
            return {
                value: item.id,
                label: item[`group_name_${lang}`]
            };
        }));
    };

    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: `${item.symbol} (${translate(`price_${lang}`)} ${item.rate})`,
                rate: item.rate,
            };
        }));
    }

    const [safe, setsafes] = useState([]);
    const getSafes = async () => {
        const { data } = await axios.get("/finance/safe");
        setsafes(data.map((item) => {
            return {
                value: item.id,
                label: `${item.safe_code} - ${item[`safe_name`]}`
            };
        }));
    };

    useEffect(() => {
        getData();
        getCurrency();
        getCustomerGroup();
        getSafes();
        getDataGroupBy()
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [orders, setorders] = useState([])
    const [companyPayment, setcompanyPayment] = useState([])
    const getCompany = async (id) => {
        const { data: orders } = await axios.get(`/order/company/${id}`);
        const { data: payment } = await axios.get(`/order/company/payment/${id}`);
        setorders(orders), setcompanyPayment(payment)
        if (orders.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.company-order')) {
                        window.$('.company-order').DataTable();
                    } else {
                        window.$('.company-order').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
        if (payment.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.company-payment')) {
                        window.$('.company-payment').DataTable();
                    } else {
                        window.$('.company-payment').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
    }

    const [sales, setsales] = useState([])
    const [buyerPayment, setbuyerPayment] = useState([])
    const getBuyer = async (id) => {
        const { data: sales } = await axios.get(`/sale/buyer/${id}`);
        const { data: payment } = await axios.get(`/sale/buyer/payment/${id}`);
        setsales(sales), setbuyerPayment(payment)
        if (sales.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.buyer-sales')) {
                        window.$('.buyer-sales').DataTable();
                    } else {
                        window.$('.buyer-sales').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
        if (payment.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.buyer-payment')) {
                        window.$('.buyer-payment').DataTable();
                    } else {
                        window.$('.buyer-payment').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`accounts_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`cusotmer_list_${lang}`)}</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`name_${lang}`)}</th>
                                        <th>{translate(`phone_no_${lang}`)}</th>
                                        <th>{translate(`address_${lang}`)}</th>
                                        <th>{translate(`amount_${lang}`)}</th>
                                        <th>{translate(`amount_paid_${lang}`)}</th>
                                        <th>{translate(`amount_due_${lang}`)}</th>
                                        <th>{translate(`currency_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.full_name}</td>
                                            <td>{item.phone_number}</td>
                                            <td>{item.address}</td>
                                            <td>{item.amount} {item.symbol}</td>
                                            <td>{item.amount_paid} {item.symbol}</td>
                                            <td>{item.amount_due} {item.symbol}</td>
                                            <td>{item.symbol}</td>
                                            <td>
                                                {item.customer_type === "buyer" ?
                                                    <span type="button" className="badge bg-dark mx-1" data-bs-toggle="modal" data-bs-target={`#inv${item.id}`}>
                                                        <icon.FileText size={16} />
                                                    </span>
                                                    : null
                                                }
                                                {item.customer_type === "buyer" ?
                                                    <span type="button" className="badge bg-primary mx-1" data-bs-toggle="modal" data-bs-target={`#printer${item.id}`}>
                                                        <icon.Printer size={16} />
                                                    </span> :
                                                    <a href={`/company/invoice/${item.id}/${item.currency_id}`} className="badge bg-primary mx-1">
                                                        <icon.Printer size={16} />
                                                    </a>
                                                }
                                                <span type="button" className="badge bg-success mx-1" data-bs-toggle="modal" data-bs-target={`#paymentModalcustomer${item.id}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-credit-card"><rect x={1} y={4} width={22} height={16} rx={2} ry={2} /><line x1={1} y1={10} x2={23} y2={10} /></svg>
                                                </span>

                                            </td>
                                            {/* we will display the dialog box to pay the amount due */}
                                            <div className="modal fade" id={`paymentModalcustomer${item.id}`} tabIndex={-1} aria-labelledby={`payment${index + 1}`} aria-hidden="true">
                                                <div className="modal-dialog modal-md">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title"> {translate(`customer_${lang}`)}</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                        </div>
                                                        <form className="form-sample" onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            formData.append('customer_id', item.id);
                                                            formData.append('currency_id', item.currency_id);
                                                            const data = Object.fromEntries(formData);
                                                            axios.post(`/customer/paymentapi`, data).then(res => {
                                                                if (res.data !== 'done') {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    window.$(`#paymentModalcustomer${item.id}`).modal('hide')
                                                                    e.target.reset();
                                                                    getData();
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label htmlFor="unpaid" className="col-form-label">{translate(`title_${lang}`)}  </label>
                                                                    <input type="text" className="form-control" name="title"  required/>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="unpaid" className="col-form-label">{translate(`amount_due_${lang}`)} {item.symbol}</label>
                                                                    <input type="number" className="form-control" id={`unpaid${item.id}`} defaultValue={item.amount_due} disabled />
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="safe_id" className="form-label">{translate(`safes_${lang}`)} <span className="text-danger">*</span></label>
                                                                        <Select options={safe} name="safe_id" placeholder={translate(`safes_${lang}`)} required />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="amount_paid" className="col-form-label">{translate(`amount_${lang}`)} <span className="text-danger">*</span></label>
                                                                    <input type="number" className="form-control" name="amount" id={`paid${item.id}`} step={"any"} defaultValue={0} onChange={(e) => {
                                                                        if (e.target.value > item.amount_due || e.target.value < 0) {
                                                                            $(`#paid${item.id}`).val(0);
                                                                            $(`#unpaid${item.id}`).val(item.amount_due);
                                                                        } else {
                                                                            $(`#unpaid${item.id}`).val((item.amount_due - e.target.value));
                                                                        }
                                                                    }} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">{translate(`date_${lang}`)} <span className="text-danger">*</span></label>
                                                                    <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString().split('T')[0]} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="description" className="col-form-label">{translate(`note_${lang}`)}</label>
                                                                    <textarea className="form-control" name="description" rows={3} placeholder={translate(`note_${lang}`)} />
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary">{translate(`save_${lang}`)}</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`amount_${lang}`)}</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="cost2">{totalUSD} IQD </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`amount_paid_${lang}`)}</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="sold_price"> {paymentUSD} IQD </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`amount_due_${lang}`)}</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="amount_paid2"> {balanceUSD} IQD </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><br />
                            {/* <div className="row mt-4">
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`amount_${lang}`)}</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="cost2"> {totalIQD} IQD</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`amount_paid_${lang}`)}</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="sold_price"> {paymentIQD} IQD </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">{translate(`amount_due_${lang}`)}</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="amount_paid2"> {balanceIQD} IQD </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><br /> */}
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default PaymentDetailsCustomers;