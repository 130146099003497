import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import commaNumber from "comma-number";
import Chart from "react-apexcharts";

const Dashboard = ({ icon, translate, lang, role }) => {
    const [chartData, setChartData] = useState({});
    const getData = async () => {
        const { data } = await axios.get('/orderItem/item-summary');
        const options = {
            title: {
                text: translate(`available_items_${lang}`),
                align: 'center',
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontFamily: "sam",
                }
            },
            xaxis: {
                categories: data.map(item => item[`item_name_${lang}`]),
            },
            chart: {
                toolbar: {
                    show: false,
                },
            },
            tooltip: {
                y: {
                    formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                        const item = w.config.series[seriesIndex].data[dataPointIndex];
                        return `${val} ${item.unit === "m" ? translate(`sqm_${lang}`) : item.unit === "qty" ? translate(`qty_text_${lang}`) : translate(`kg_${lang}`)}<br/>${translate(`price_${lang}`)}: ${item.max_price} $`;
                    }
                },
            }
        };
        const series = [{
            name: translate(`order_${lang}`),
            data: data.map(item => ({
                x: item[`item_name_${lang}`],
                y: item.qty_order,
                unit: item.item_unit,
                max_price: item.max_price,
            })),
        }, {
            name: translate(`producted_${lang}`),
            data: data.map(item => ({
                x: item[`item_name_${lang}`],
                y: item.qty_production,
                unit: item.item_unit,
                max_price: item.max_price,
            })),
        }, {
            name: translate(`damages_${lang}`),
            data: data.map(item => ({
                x: item[`item_name_${lang}`],
                y: item.qty_damages,
                unit: item.item_unit,
                max_price: item.max_price,
            })),
        }, {
            name: translate(`available_items_${lang}`),
            data: data.map(item => ({
                x: item[`item_name_${lang}`],
                y: item.item_balance,
                unit: item.item_unit,
                max_price: item.max_price,
            })),
        }];

        setChartData({
            options: options,
            series: series,
        });
    };

    const [sales, setSales] = useState([])
    const getSales = async () => {
        const { data } = await axios.get('/sale/list');
        setSales(data)
    }


    const [stats, setStats] = useState([]);
    useEffect(() => {
        axios.get('stats').then(res => {
            const order_amount = 0;
            const sale_amount = 0;
            const total_sale = 0;
            const total_production = 0;
            const available_production = 0;
            setStats(
                <div className="col-md-12">
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> {translate(`total_order_${lang}`)}</span>
                                            <h3 className="card-title mb-2">{order_amount.total_disc ? commaNumber(order_amount.total_disc.toFixed(2)) : 0} $</h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> {translate(`paid_order_${lang}`)}</span>
                                            <h3 className="card-title mb-2">{order_amount.amount_paid ? commaNumber(order_amount.amount_paid.toFixed(2)) : 0} $</h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.Download /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1">{translate(`due_order_${lang}`)}</span>
                                            <h3 className="card-title mb-2">{order_amount.amount_due ? commaNumber(order_amount.amount_due.toFixed(2)) : 0} $</h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.AlertTriangle /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1">{translate(`total_sale_${lang}`)}</span>
                                            <h3 className="card-title mb-2">{sale_amount.total_disc ? commaNumber(sale_amount.total_disc.toFixed(2)) : 0} $</h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.TrendingUp /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> {translate(`paid_sale_${lang}`)}</span>
                                            <h3 className="card-title mb-2">{sale_amount.amount_paid ? commaNumber(sale_amount.amount_paid.toFixed(2)) : 0} $</h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.ThumbsUp /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> {translate(`due_sale_${lang}`)}</span>
                                            <h3 className="card-title mb-2">{sale_amount.amount_due ? commaNumber(sale_amount.amount_due.toFixed(2)) : 0} $</h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.ThumbsDown /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> {translate(`producted_${lang}`)}</span>
                                            <h3 className="card-title mb-2">{total_production.total ? commaNumber(total_production.total) : 0}</h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.Layers /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1">{translate(`product_sold_${lang}`)}</span>
                                            <h3 className="card-title mb-2">{total_sale.total ? commaNumber(total_sale.total) : 0}</h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.Truck /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> {translate(`available_products_${lang}`)}</span>
                                            <h3 className="card-title mb-2">{available_production.total ? commaNumber(available_production.total) : 0}</h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.Percent /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        getSales();
        getData();
        // eslint-disable-next-line
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`stats_${lang}`)}</li>
                </ol>
            </nav>
            {/* row center content */}
            <div className="row ">
                <div className="col-md-8">
                    <div className="row ">
                        {stats}
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    {/* {chartData.series && (
                                        <Chart options={chartData.options} series={chartData.series} type="bar" height={350} />
                                    )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            {/* sales */}
                            <div className="d-flex justify-content-between align-items-baseline mb-2">
                                <h6 className="card-title mb-0">{translate(`sale_list_${lang}`)}</h6>
                            </div>
                            {sales.map((item, index) => (
                                <div className="d-flex flex-column" key={index}>
                                    <Link to={`/sale/invoice/${item.id}`} className="d-flex align-items-center border-bottom pb-3 mb-3">
                                        <div className="me-3">
                                            <icon.CheckCircle className="wd-35 text-dark" />
                                        </div>
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between">
                                                <h6 className="text-body mb-2">{item.customer_name}  {commaNumber(item.amount)} IQD </h6>
                                                <p className="text-muted tx-12">{new Date(item.created).toLocaleDateString()}</p>
                                            </div>
                                            <p className="text-muted tx-13">{translate(`amount_paid_${lang}`)} {commaNumber(item.amount_paid)} IQD - {translate(`amount_due_${lang}`)} {commaNumber(item.amount_due)} IQD </p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Dashboard;