/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import commaNumber from 'comma-number';

const Edit = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "operations" && role !== "orders") {
        window.location.assign('/')
    }
    const navigate = useNavigate();
    const orderId = useParams().id
    useEffect(() => {
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [seller, setSeller] = useState([]);
    const getSeller = async () => {
        const { data } = await axios.get('/customer/list');
        setSeller(data.map((item) => {
            return {
                value: item.id,
                label: item[`full_name`],
            };
        }));
    }

    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: `${item.symbol} (${translate(`price_${lang}`)} ${item.rate})`,
            };
        }));
    }

    const [store, setStore] = useState([]);
    const getStore = async () => {
        const { data } = await axios.get('/store/list');
        setStore(data.map((item) => {
            return {
                value: item.id,
                label: item[`name`],
            };
        }));
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    const [items, setItems] = useState([]);
    const getItems = async () => {
        const { data } = await axios.get('/item/list');
        setItems(data.map((item) => {
            return {
                value: item.id,
                label: `${item[`item_name`]} - ${item.item_code}`,
                item_unit: item.item_unit,
            };
        }));
    }

    const [safe, setsafe] = useState([]);
    const getSafe = async () => {
        const { data } = await axios.get("/finance/safe");
        setsafe(data.map((item) => {
            return {
                value: item.id,
                label: `${item.safe_code} - ${item[`safe_name`]}`
            };
        }));
    };

    const itemSelected = (e) => {
        axios.post(`/orderItem/add`, { order_id: orderId, item_id: e.value, item_unit: e.item_unit }).then(res => {
            if (isNaN(res.data)) {
                toast.error(res.data, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setOrderItem([])
                getOrderItem();
            }
        })
    }

    const [orderItem, setOrderItem] = useState([]);
    const getOrderItem = async () => {
        const { data } = await axios.get(`/orderItem/list/${orderId}`);
        setOrderItem(data);
    }

    useEffect(() => {
        getItems();
        getStore();
        getSeller();
        getSafe();
        getCurrency();
        getOrderItem();
        // eslint-disable-next-line
    }, []);

    const [order, setOrder] = useState([]);
    const [orderDate, setOrderDate] = useState([])
    const [symbol, setSymbol] = useState([])
    const [currentSeller, setCurrentSeller] = useState([])
    const [currentStore, setCurrentStore] = useState([])
    const [currentCurrency, setCurrentCurrency] = useState([])
    const [currentSafe, setcurrentSafe] = useState([])
    const [amount_paid, setAmountPaid] = useState([])
    const [amount_due, setAmountDue] = useState([])
    const [discount, setDiscount] = useState([])
    useEffect(() => {
        axios.get(`/order/list/${orderId}`).then((res) => {
            setOrder(res.data);
            setOrderDate(<input type="date" className="form-control" name="order_date" defaultValue={new Date().toISOString(res.data.order_date).split('T')[0]} />)
            setSymbol(res.data.symbol)
            setCurrentSeller(<Select options={seller} name="customer_id" placeholder={translate(`company_${lang}`)} defaultValue={{ value: res.data.customer_id, label: res.data[`customer_name`] }} required />)
            setCurrentStore(<Select options={store} name="store_id" placeholder={translate(`store_${lang}`)} defaultValue={{ value: res.data.store_id, label: res.data[`store_name`] }} required />)
            setcurrentSafe(<Select options={safe} name="safe_id" placeholder={translate(`safe_name_${lang}`)} defaultValue={{ value: res.data.safe_id, label: res.data[`safe_name`] }} required />)
            setCurrentCurrency(<><Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} defaultValue={{ value: res.data.currency_id, label: res.data.symbol }} required onChange={(e) => {
                localStorage.setItem('currency', JSON.stringify(e.label));
                $('input[name="rate"]').val(e.rate);
            }} />
                <input type="hidden" name="rate" defaultValue={res.data.rate} required /> </>)
            setAmountPaid(<p>{res.data.amount_paid} {symbol}</p>)
            setAmountDue(<p>{res.data.amount_due} {symbol}</p>)
            setDiscount(<input type="number" className="form-control" id="discount" name="discount" min={0} step="any" defaultValue={res.data.discount} onChange={(e) => { axios.put(`/order/update/${orderId}`, { discount: e.target.value }).then(); }} />)
        })
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`edit_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card" id="orders">
                    <div className="card">
                        <form className="form-sample" onSubmit={(e) => {
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const data = Object.fromEntries(formData);
                            axios.put(`/order/update/${orderId}`, data).then(res => {
                                if (isNaN(res.data)) {
                                    toast.error(res.data, {
                                        position: "top-left",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                } else {
                                    $('#orders').hide();
                                    $('#order_item').show();
                                    getOrderItem();
                                }
                            });
                        }}>
                            <div className="card-body">
                                <h5 className="card-title"> {translate(`edit_${lang}`)}</h5>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">{translate(`invoice_no_${lang}`)}</label>
                                        <input type="text" className="form-control" name="invoice_no" defaultValue={order.invoice_no} required />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">{translate(`company_${lang}`)}</label>
                                        {currentSeller}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">{translate(`store_${lang}`)}</label>
                                        {currentStore}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label"> {translate(`currency_${lang}`)}</label>
                                        {currentCurrency}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label"> {translate(`safe_name_${lang}`)}</label>
                                        {/* <Select options={safe} name="safe_id" placeholder={translate(`safe_name_${lang}`)} required /> */}
                                        {currentSafe}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label"> {translate(`attachment_${lang}`)}</label>
                                        <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                        <small className="text-muted">{order.attachment}</small>
                                        <input type="hidden" name="attachment" defaultValue={selectedFile} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label"> {translate(`date_${lang}`)}</label>
                                        {orderDate}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">{translate(`note_${lang}`)}</label>
                                        <textarea className="form-control" name="note" defaultValue={order.note} />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary">{translate(`next_${lang}`)}
                                    <icon.ArrowLeft className="mx-2 mt-1" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-12 grid-margin stretch-card" id="order_item" style={{ display: "none" }}>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{translate(`select_item_${lang}`)}</h5>
                            <div className="row">
                                <div className="col-md-4 mb-3 mt-2">
                                    <div className="input-group mb-3">
                                        <div className="col-12">
                                            <Select options={items} placeholder={translate(`select_item_${lang}`)} onChange={(e) => { itemSelected(e); }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="table-responsive">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{translate(`item_${lang}`)}</th>
                                                    <th>{translate(`kg_${lang}`)}</th>
                                                    <th>{translate(`price_${lang}`)}</th>
                                                    <th>{translate(`qty_${lang}`)}</th>
                                                    <th>{translate(`total_price_${lang}`)}</th>
                                                    <th>{translate(`options_${lang}`)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderItem.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.id}</td>
                                                        <td>{item[`item_name`]}</td>
                                                        <td>
                                                            <input type="number" className="form-control" name="kg" step="any" defaultValue={item.kg} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/orderItem/update/${item.id}`, { kg: e.target.value }).then(() => {
                                                                    getOrderItem();
                                                                });
                                                            }} />
                                                        </td>
                                                        <td>
                                                            <input type="number" className="form-control" name="price" step="any" defaultValue={item.price} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/orderItem/update/${item.id}`, { price: e.target.value }).then(() => {
                                                                    getOrderItem();
                                                                });
                                                            }} />
                                                        </td>
                                                        <td>
                                                            <input type="number" className="form-control" name="unit" step="any" defaultValue={item.unit} onChange={(e) => {
                                                                if (e.target.value === '0') {
                                                                    toast.error(translate(`zero_not_allowed_${lang}`));
                                                                    return;
                                                                };
                                                                axios.put(`/orderItem/update/${item.id}`, { unit: e.target.value }).then(() => {
                                                                    getOrderItem();
                                                                });
                                                            }} />
                                                        </td>
                                                        <td>{commaNumber(item.amount)} {symbol}</td>
                                                        <td>
                                                            <span type="button" className="badge bg-danger" onClick={() => {
                                                                axios.delete(`/orderItem/list/${item.id}`).then(() => {
                                                                    getOrderItem();
                                                                });
                                                            }}>
                                                                <icon.Trash />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row mt-3">
                                <div className="col-md-5 mb-3">
                                    <h5 className="card-title">{translate(`payment_status_${lang}`)}</h5>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="discount">{translate(`discount_${lang}`)}</label>
                                        <div className="input-group flex-nowrap">
                                            {discount}
                                            <span className="input-group-text" id="addon-wrapping"><span className="mx-1">%</span></span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="paid">{translate(`amount_paid_${lang}`)}</label>
                                        <div className="input-group flex-nowrap">
                                            {amount_paid}
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="paid">{translate(`amount_due_${lang}`)}</label>
                                        <div className="input-group flex-nowrap">
                                            {amount_due}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 mb-3"></div>
                                <div className="col-md-5 mb-3">
                                    <h5 className="card-title">{translate(`summery_order_${lang}`)}</h5>
                                    <div className="table-responsive">
                                        <table className="table table-sm">
                                            <tbody>
                                                <tr className="bg-light">
                                                    <td className="text-bold-800">{translate(`qty_${lang}`)}</td>
                                                    <td className="text-bold-800 text-end">{commaNumber(orderItem.reduce((total, item) => total + item.unit, 0).toFixed(2))} {translate(`qty_text_${lang}`)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-bold-800"> {translate(`weight_${lang}`)}</td>
                                                    <td className="text-bold-800 text-end">{commaNumber(orderItem.reduce((total, item) => total + (item.kg), 0).toFixed(2))} {translate(`kg_${lang}`)}</td>
                                                </tr>
                                                <tr className="bg-dark text-white">
                                                    <td className="text-bold-800">{translate(`total_${lang}`)}</td>
                                                    <td className="text-bold-800 text-end"> {commaNumber(orderItem.reduce((total, item) => total + item.amount, 0).toFixed(2))} {symbol}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3 mt-3 float-end">
                                        <button type="submit" className="btn btn-success" onClick={() => { navigate('/orders/list'); }} >
                                            {translate(`update_${lang}`)}
                                            <icon.Save className="mx-2 mt-1" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Edit;